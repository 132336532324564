import {Alert, Spinner} from "@patternfly/react-core";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {sendNotification} from "./api/apiCalls";

const SendNotification = () => {
    const history = useHistory();
    const [msg, setmsg] = useState();

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);

    const [title, setTitle] = useState("");
    const [msgBody, setMsgBody] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [deeplinkUrlIOS, setDeeplinkUrlIOS] = useState("");
    const [deeplinkUrlANDROID, setDeeplinkUrlANDROID] = useState("");

    const [titleError, setTitleError] = useState("");
    const [bodyError, setBodyError] = useState("");

    const onSubmit = async event => {
        let error = false;

        if (!title) {
            setTitleError("Bildirim başlığı boş olamaz");
            error = true;
        } else {
            setTitleError(null);
        }
        if (!msgBody) {
            setBodyError("Bildirim içeriği boş olamaz");
            error = true;
        } else {
            setBodyError(null);
        }

        if (error) {
            return
        }

        event.preventDefault();
        setSending(true);
        const body = {
            "title": title,
            "body": msgBody,
            "imageUrl": imageUrl,
            "deeplinkUrlIOS": deeplinkUrlIOS,
            "deeplinkUrlANDROID": deeplinkUrlANDROID,
        }

        try {
            let response = await sendNotification(body);
            if (response.status === 201) {
                setmsg("Bildirimler gönderilmeye başlandı...")
                setSuccess(true);
                setTitle("");
                setMsgBody("");
                setImageUrl("");
                setDeeplinkUrlIOS("");
                setDeeplinkUrlANDROID("");

            } else {
                setmsg(response.response.data?.message)
                setSuccess(false);
                setSending(false);
                setTitleError(response.response.data?.validationErrors?.title);
                setBodyError(response.response.data?.validationErrors?.body);
            }
        } catch (error) {
            setmsg(error.response.data.validationErrors)
            setSuccess(false);
            setSending(false);
            setTitleError(error.response.data.validationErrors.title);
            setBodyError(error.response.data.validationErrors.body);
        }
        setSending(false);
    };

    return (
        <>
            <section className="sign_in_area bg_color sec_pad">
                <div className="container">
                    {success ?
                        <Alert className="popup-fixed alert_sign" variant="success" timeout="3000" title={msg}/> : ""}
                    <div className="sign_info">
                        <div className="row">
                            <div className="container">
                                <h2 className="f_p f_600 f_size_24 t_color3 mb_40">Toplu Bildirim Gönder</h2>
                                <form action="#" className="">
                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Bildirim Başlığı*</label>
                                        <input type="text" value={title} onChange={(e) => {
                                            setTitle(e.target.value)
                                            setTitleError(null)
                                        }}
                                               required/>
                                        <span style={{color: "red"}}> {titleError} </span>
                                    </div>

                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Bildirim İçeriği*</label>
                                        <textarea value={msgBody} onChange={(e) => {
                                            setMsgBody(e.target.value)
                                            setBodyError(null);
                                        }}/>
                                        <span style={{color: "red"}}> {bodyError} </span>
                                    </div>

                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Bildirim Görseli (opsiyonel)</label>
                                        <input type="text" value={imageUrl} onChange={(e) => {
                                            setImageUrl(e.target.value)
                                        }}/>
                                    </div>

                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">Android Deeplink (opsiyonel)</label>
                                        <input type="text" value={deeplinkUrlANDROID} onChange={(e) => {
                                            setDeeplinkUrlANDROID(e.target.value)
                                        }}/>
                                    </div>

                                    <div className="form-group text_box">
                                        <label className="f_p text_c f_400">IOS Deeplink (opsiyonel)</label>
                                        <input type="text" value={deeplinkUrlIOS} onChange={(e) => {
                                            setDeeplinkUrlIOS(e.target.value)
                                        }}/>
                                    </div>

                                    <div>
                                        {!sending ? <button disabled={sending} type="button" onClick={onSubmit}
                                                            className="btn_three">Gönder</button> : <Spinner/>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SendNotification;
